@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');

* {
  /*text-align: center;*/
  font-family: 'Quicksand', sans-serif;
}



/* width */
::-webkit-scrollbar {
  width: 0.33vw;
  height:0.75vh;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(7, 80, 236, 0.5); 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 38, 255, 0.418); 
  border-radius: 10px;
}

::-webkit-scrollbar:hover {
  width: 0.4vw;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #10957d; 
}




/* The side navigation menu */
.sidenav {
  height: 100%; /* 100% Full-height */
  width: 10vw; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #fff; 
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;/* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.1s; /* 0.5 second transition effect to slide in the sidenav */
  

}


/* The navigation menu links */
.sidenav a {
  padding: 15px 8px 15px 25px;
  text-decoration: none;
  font-size: 20px;
  color: #1a8cff;
  display: block;
  transition: 0.3s;
  text-align: left;
}

.sidenav path{
  color:#1a8cff;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.content{
  position:fixed;
  top:5vh;
  height:95vh;
  left:10vw;
  width:90vw;
  text-align: center;
  overflow-y: auto;
  z-index:0;
}

.logo img{
  object-fit: contain;
  max-width:75%;
  margin-bottom: 5vh;  
  text-align:center;
  }
  
  .inline{
    display:inline;
  }


  table, tr, td{

    width:100%;
    padding:10px;
    text-align: center;

  }

  thead{
    position:fixed;
  }


  .container{
    display: flex;
  }

  .TaskList{
    min-height: 100px;
    display: flex;
    flex-direction: column;
    background: #f3f3f3;
    min-width: 341px;
    border-radius: 5px;
    padding: 15px 15px;
    margin-right: 45px;
  }

  .TaskColumnStyles{
    margin: 8px;
    display: flex;
    width: 100%;
    min-height: 80vh;
  }

  .Title{
    color: #10957d;
    background: rgba(16, 149, 125, 0.15);
    padding: 2px 10px;
    border-radius: 5px;
    align-self: flex-start;
  }

.TaskInformation{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 15px;
  min-height: 106px;
  border-radius: 5px;
  max-width: 311px;
   } 


  .status,
  .days,
  .time {
    font-size: 14px;
    margin-bottom: 7px;
  }
  
/*  .order .status {
    background: #e48e16;
    color: #494949;
    padding: 3px 10px;
    border-radius: 18px;
    font-weight: bold;
  }
  .done .status {
    background: #c8ebdd;
    color: #494949;
    padding: 3px 10px;
    border-radius: 18px;
  }
  .pending .status {
    background: #cfd8f0;
    color: #494949;
    padding: 3px 10px;
    border-radius: 18px;
  }
  .waiting .status {
    background: #eac8d7;
    color: #494949;
    padding: 3px 10px;
    border-radius: 18px;
  }*/

  h4 {
    font-family: sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 0px;
    color: #416183;
  }
  .container {
    max-height: 95vh;
    width: 89vw;
    margin: 0 auto;
    padding: 0;
    overflow-y:unset;

  }
  .drag_column {
    background-color: #e2e2e2;
    width:16vw;
    padding: 0px  10px ;
    height: 92vh;
    border-right:10px solid #fff;
    top:5vh;
    bottom:0;
    overflow-y: scroll;
    
  }
  .drag_row {
    width: 90%;
    margin-left:5%
  }



  .columnHeader{
    padding:20px 0 0 0;

    position: sticky;
    top:0;
    margin-bottom:5px;
    background-color:#e2e2e2;
    width:100%;
    z-index:2500
  }


  .small-box {
    
    background: #f7f8fa;
    height: max-content;
  }
  
  .task {
    border: 1px solid white;
    margin: 10px;
    box-shadow: 1px 3px 10px gray;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .inProgress{
    box-shadow:1px 3px 10px rgb(149, 206, 149);
  }

  .notStarted{
    box-shadow:1px 3px 10px rgb(206, 149, 149);
  }

  .ticketContent-3{
    width:100%;
    display:flex;
    height:85vh;
  }

  .ticketContent-unknown{
    width:max-content;
    overflow-x: scroll;
    display:flex;
    height:90vh;
  }

  .ticketBucket{
    max-width:30%;
    width:25vw;
    float:left;
   margin:0 2vw 0 2vw;
    overflow-y: scroll;
    background-color: #d1dae4;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

  }

  .bucketContent{
     margin:0 2vw 0 2vw;
     
  }
  
.ticketBucketHeader{
  position:sticky;
  top:0;
  z-index:1000;
  background-color: #1a8cff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color:#fff;
  text-transform: capitalize;
  font-weight: 800;
  padding: 10px 0 10px 0 ;
}



.request-item{
    width: auto;
    margin-right:1vw;
    margin-left:0;
    margin-bottom:15px;

}

  .requestApprove{
    background-color: #10957d;
    color:white;
    border-radius:5px;
    margin-right:5px;
    border:none;
    padding:5px 10px;
    cursor:pointer
  }

  .requestPark{
    background-color:#e48e16;
    color:white;
    border-radius:5px;
    margin-right:5px;
    border:none;
    padding:5px 10px;
    cursor:pointer
  }

  .requestReject{
    background-color:rgb(185, 32, 32);
    color:white;
    border-radius:5px;
    margin-right:5px;
    border:none;
    padding:5px 10px;
    cursor:pointer
  }

  .requestTypeissue{
    font-size: 16px;
    display: flex; 
    justify-content: center;
    text-transform: capitalize;
    background-color:rgb(72, 101, 182);
    color:white;
    padding:5px 0 5px 0;
    border:none;
    position:relative;
    display:flex;
    left:0;
    top:0px;
    width:100%;
  }

  .requestTypewish{
    font-size: 16px;
    display: flex; 
    justify-content: center;
    text-transform: capitalize;
    background-color:rgb(169, 72, 182);
    color:white;
    padding:5px 0 5px 0;
    border:none;
    position:relative;
    display:flex;
    width:100%;
    
  }

  .requestTypeinvestigation{
    font-size: 16px;
    display: flex; 
    justify-content: center;
    text-transform: capitalize;
    background-color:rgb(226, 175, 80);
    color:white;
    padding:5px 0 5px 0;
    border:none;
    position:relative;
    display:flex;
    left:0;
    top:0px;
    width:100%;
  }

  .requestTypenew{
    font-size: 16px;
    display: flex; 
    justify-content: center;
    text-transform: capitalize;
    background-color:rgb(72, 182, 118);
    color:white;
    padding:5px 0 5px 0;
    border:none;
    position:relative;
    display:flex;
    left:0;
    top:0px;
    width:100%;
  }

  

  .requestHeaderinvestigation{

    padding:10px;
    border-right:1px solid rgb(226, 175, 80);
    border-left:1px solid rgb(226, 175, 80);
    border-bottom:1px solid rgb(226, 175, 80);
    background-color:#fff;
  }


  .requestHeaderissue{

    padding:10px;
    border-right:1px solid rgb(72, 101, 182);
    border-left:1px solid rgb(72, 101, 182);
    border-bottom:1px solid rgb(72, 101, 182);
    background-color:#fff;
  }

  
  .requestHeaderwish{

    padding:10px;
    border-right:1px solid rgb(169, 72, 182);
    border-left:1px solid rgb(169, 72, 182);
    border-bottom:1px solid rgb(169, 72, 182);
    background-color:#fff;
  }

  .requestHeadernew{

    padding:10px;
    border-right:1px solid rgb(72, 182, 118);
    border-left:1px solid rgb(72, 182, 118);
    border-bottom:1px solid rgb(72, 182, 118);
    background-color:#fff;
  }



  .priorityFlag1{
    font-weight: bolder;
    width:25px;
    height:25px;
    position:relative;
    float:right;
    margin-bottom: -37px;
    z-index:100;
    margin-right:-14px;
    color:#fff;
    background-color: rgb(235, 92, 92);
    padding:12px 10px 8px 10px;
    border-radius:150px;
    border:1px solid #fff;
    box-shadow: rgb(184, 180, 180) -2px 2px 6px;
  }

  .priorityFlag2{
    font-weight: bolder;
    width:25px;
    height:25px;
    position:relative;
    float:right;
    margin-bottom: -37px;
    z-index:100;
    margin-right:-14px;
    color:#fff;
    background-color: rgb(245, 185, 35);
    padding:12px 10px 8px 10px;
    border-radius:150px;
    border:1px solid #fff;
    box-shadow: rgb(184, 180, 180) -2px 2px 6px;
  }

  .priorityFlag3{
    font-weight: bolder;
    width:25px;
    height:25px;
    position:relative;
    float:right;
    margin-bottom: -37px;
    z-index:100;
    margin-right:-14px;
    color:#fff;
    background-color: rgb(149, 207, 73);
    padding:12px 10px 8px 10px;
    border-radius:150px;
    border:1px solid #fff;
    box-shadow: rgb(184, 180, 180) -2px 2px 6px;
  }

  .priorityFlag4{
    font-weight: bolder;
    width:25px;
    height:25px;
    position:relative;
    float:right;
    margin-bottom: -37px;
    z-index:100;
    margin-right:-14px;
    color:#fff;
    background-color: rgb(73, 207, 207);
    padding:12px 10px 8px 10px;
    border-radius:150px;
    border:1px solid #fff;
    box-shadow: rgb(184, 180, 180) -2px 2px 6px;
  }

  .priorityFlag5{
    font-weight: bolder;
    width:25px;
    height:25px;
    position:relative;
    float:right;
    margin-bottom: -37px;
    z-index:100;
    margin-right:-14px;
    color:#fff;
    background-color: rgb(207, 73, 162);
    padding:12px 10px 8px 10px;
    border-radius:150px;
    border:1px solid #fff;
    box-shadow: rgb(184, 180, 180) -2px 2px 6px;
  }

  input{
    text-align:center;
  }

  .card {
    background: #fff;
    box-shadow: 3px 1px 12px #504f4f;
    border-radius: 14px;
    margin-bottom:25px;
    cursor: move;
    font-weight: 500;
  }


.plannerPriority1{
  font-weight: bolder;
  width:10px;
  height:10px;
  position:relative;
  float:right;
margin-top:-5px;
  z-index:100;
  margin-right:-8px;
  color:#fff;
  background-color: rgb(235, 92, 92);
  padding:0px 10px 10px 10px;
  border-radius:150px;
  border:1px solid #fff;
  box-shadow: rgb(124, 122, 122) -2px 2px 6px;
}

.plannerPriority2{
    font-weight: bolder;
    width:10px;
    height:10px;
    position:relative;
    float:right;
  margin-top:-5px;
    z-index:100;
    margin-right:-8px;
    color:#fff;
    background-color: rgb(245, 185, 35);
    padding:0px 10px 10px 10px;
    border-radius:150px;
    border:1px solid #fff;
    box-shadow: rgb(124, 122, 122) -2px 2px 6px;
  
}

.plannerPriority3{
  font-weight: bolder;
  width:10px;
  height:10px;
  position:relative;
  float:right;
margin-top:-5px;
  z-index:100;
  margin-right:-8px;
  color:#fff;
  background-color: rgb(149, 207, 73);
  padding:0px 10px 10px 10px;
  border-radius:150px;
  border:1px solid #fff;
  box-shadow: rgb(124, 122, 122) -2px 2px 6px;
}

.plannerPriority4{
  font-weight: bolder;
  width:10px;
  height:10px;
  position:relative;
  float:right;
margin-top:-5px;
  z-index:100;
  margin-right:-8px;
  color:#fff;
  background-color: rgb(73, 207, 207);
  padding:0px 10px 10px 10px;
  border-radius:150px;
  border:1px solid #fff;
  box-shadow: rgb(124, 122, 122) -2px 2px 6px;
}

.plannerPriority5{
  font-weight: bolder;
  width:10px;
  height:10px;
  position:relative;
  float:right;
margin-top:-5px;
  z-index:100;
  margin-right:-8px;
  color:#fff;
  background-color: rgb(207, 73, 162);
  padding:0px 10px 10px 10px;
  border-radius:150px;
  border:1px solid #fff;
  box-shadow: rgb(124, 122, 122) -2px 2px 6px;
}

.plannerTypenewdevelopment{
  text-transform: capitalize;
  background-color:rgb(51, 194, 158);
  color:#fff;
}

.plannerTypewish{
  text-transform: capitalize;
  background-color:rgb(169, 72, 182);
  color:#fff;
}

.plannerTypeissue{
  text-transform: capitalize;
  background-color:rgb(72, 101, 182);
  color:#fff;
}

.plannerTypeinvestigation{
  text-transform: capitalize;
  background-color:rgb(226, 175, 80);
  color:#fff;
}


.plannerDescription {
  border-bottom: 1px solid #1a8cff25;
  padding: 10px 5px;
  font-weight: 700
}         
.plannerStats{
  padding-top: 10px;
  padding-bottom: 10px;
}



#approvalSideBarProducts{
  text-transform: capitalize;
}









  .approvalButtons{
    padding-top:10px;
    padding-bottom: 10ox;;
  } 


  .updateAlert{

    display:none;
    position:absolute;
    top:0;
    right:0;
    background-color:#10957d80;
    padding:10px 25px;

  }

  .approvalSideBar{
    display: none;
    border-top-left-radius: 15px;
    position:fixed;
    width:33.33vw;
    top:6vh;
    right:0;
    height:95vh;
    background-color: #fff;
    z-index:3500;
    box-shadow: rgba(46, 46, 46, 0.35) -15px -10px 25px;
  }

  .closeModalCross{
    position:fixed;
    right:31vw;
    top: 6vh;
    padding:15px;
    font-weight: bolder;
    color:#fff;
    text-align:left;
    cursor:pointer
  }

.sidebarHeader{
  padding-left:3vw;
  background-color: #1a8cff;
  border-top-left-radius: 15px;
  display:flex;
  color: #fff;
  justify-content: center;
  text-transform: capitalize;
  font-weight: bolder;
}

.approvalSideBarType{
  text-transform: uppercase;
}


.sidebarContent{
  padding:25px;
}


.sidebarCard{
border-bottom: 1px dotted #1a8dff4f;
}

.sidebarCardHeader{
  margin-top:15px;
  font-weight: bold;
  text-transform: uppercase;
  color:#1a8cff;
}

.sidebarCardInfo{
  max-height:150px;
  overflow-y: auto;
  font-weight: 700;
  white-space:pre-line;
}

.sidebarPriority{
  position: fixed;
  top:4.5vh;
  right:0.5vw;
  color:#fff;
  padding:0px 20px;
  background-color:#e48e16;
  border-radius:20px;
  font-weight: bolder;
  cursor:pointer;
  box-shadow: rgba(46, 46, 46, 0.35) 5px 10px 15px;
}
.priorityChangeMenu{
  position: fixed;
  top:10.5vh;
  right:0.4vw;
  color:#fff;
  padding:10px 0px;
  background-color:#e48e16;
  border-radius:10px;
  font-weight: bolder;
  cursor:pointer;
  display:none;
  box-shadow: rgba(46, 46, 46, 0.35) 5px 10px 15px;
}

.priorityChangeOption{
  border-bottom:1px dotted rgba(255, 255, 255, 0.849);
  padding:5px 20px
}

.priorityChangeOption:hover{
background-color: #10957d;
}

.approvalField{
margin-right:10px;
width:130px;
height:25px;
border-radius:15px;
border:1px solid #10957d;
font-weight: 800;
font-size:large
}

.approvalSubmit{
  border-radius:10px;
  border:none;
  background-color: #fff;
  padding:5px 10px;
  color:#10957d;
  font-weight: 800;
font-size:large;
cursor:pointer
}

.approvalForm{
  margin:0;
  padding:15px 25px;
  border:1px solid #10957d;
  background-color: #10957d;
}

.updateStatusContextMenu{
  position:absolute;
  box-sizing:border-box;
  width:200px;
  border:none;
  background-color: rgb(78, 78, 78);
  color:#fff;
  z-index:1500;
  border-radius:10px;
  display:none;
}

.contextOption{
  padding:5px 10px;
  border-bottom:1px dotted rgb(160, 160, 160);
  
}

.contextOption:hover{
  background-color: #10957d;
  cursor:pointer
}

.contextHeader{

padding-top:10px;
padding-bottom: 10px;
background: #1a8cff;
border-top-right-radius: 10px;
border-top-left-radius: 10px;

}

.oneRow{
  display:inline-block
}

.suggestedItemCard{
  width:20vw;
}

.suggestedItemListHeader{
  margin-top:40px;
}

.contextComments{
  background-color: #1a7cff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.contextComments:hover{
  background-color: #981aff;
}




.plannerStatus{
color:#fff;
}

.Researching{
  background-color: #01a3a3;
}

.UnabletoReplicate{
  background-color: #dd5d3d;
}

.InProgress{
  background-color: #17b365;
}
.Testing{

  background-color: #951052;

}
.PendingRelease{
  background-color: #1a8cff;
}

.Released{

}



.commentHistory{
  display: none;
  border-top-left-radius: 15px;
  position:fixed;
  width:33.33vw;
  top:6vh;
  right:0;
  height:95vh;
  background-color: #fff;
  z-index:3500;
  box-shadow: rgba(46, 46, 46, 0.35) -15px -10px 25px;
}


.commentHistoryHeader{
  padding-left:3vw;
  background-color: #1a8cff;
  border-top-left-radius: 15px;
  display:flex;
  color: #fff;
  justify-content: center;
  text-transform: capitalize;
  font-weight: bolder;
  margin-bottom: 10px;
}

.commentHistoryAdd{

}

.commentHistoryComments{
  overflow-y: scroll;
  height:60vh;
  font-weight: 800;
 
 bottom:0;
 width:100%;
  justify-content: left;
}

.commentDetails{
  font-weight: 500;
  color:#1a8cff;
  text-align: left;
}

.commentRow{

  margin:5px 0 10px 0;

}



.clickable{
  cursor:pointer
}

.button{
  padding:2vh 1.5vw;
  border:1px solid #fff;
  border-radius: 10px;
}

.bluebutton{
  padding:2vh 1.5vw;
  border:1px solid #1a8cff;
  background-color: #1a8cff;
  border-radius: 10px;
  color:#fff;
}


.loading{
  width:85vw;
  height:90vh;
  z-index:750000;
  background-color: #fff;
  font-weight: 800;
  font-size: xx-large;
  
}

.loadingGif{
  padding:auto;
  justify-content: center;
  vertical-align: middle;
}

.System1{
  color:#7c7c7c
} 
.versionRow{
  font-weight: 800;
}

span.releaseVersions{
  text-align: left;
}

.addReleaseForm{
  display:none;
}

.addNewReleaseButton{
  background-color: #17b365;
  width:8vw;
  margin-left:auto;
  margin-right:25px;
  padding:0px 20px;
  border-radius:10px;
  color:#fff;
  cursor:pointer;
}

.login{
  position:fixed;
  width:100vw;
  height:100vh;
  top:0;
  left:0;
}

.loginColumn{
  width:50%;
  float:left;
  top:0;
}

.blockColour{
  background-color: #1a8cff;
  height:100vh;
  
}

.loginTitle{
  color:#fff;
  font-size: 3vw;
  justify-content: center;
  padding: 20vh 10%
}

.loginError{
  color: #862a27;
  background-color: #862a2752;
  padding:25px 0 25px 0;
  font-weight: 800;
  position:fixed;
  width:50vw
}

.loginForm{
  color:#fff;
  justify-content: center;
  margin:auto;
  margin:33vh 12.5vw
}

.loginForm{
  display:block;
  justify-content: center;
  color:#1a8cff;
  margin-bottom:2vh;
  font-weight: 800;
  letter-spacing: 0.6ch;
}

.loginFormInput{
  border-radius:10px;
  height:3vh;
  border:1px solid #1a8cff;
  width:25vw;
  margin-bottom: 2vh;
  color:#000;
  font-weight: 800;
  letter-spacing: 0.6ch;
}

.loginFormButton{
  border-radius: 10px;
  border:none;
  width:12.5vw;
  height:4vh;
  background-color: #1a8dff4d;
  font-weight: bolder;
  cursor: pointer;
}

.loginForm h2{
  color:#1a8cff
}


.userInfo {
  justify-content: space-evenly;
  width:90vw;
  height:5vh;
  background-color:#1a8cff ;
  left:10vw;
  top:0;
  position:fixed;
  color:white;
  font-weight: bold;
}

.userInfoItem{
display:flex;
float:right;
margin-left:0px;
margin-right:10px;
}

.userInfoItemNext{
  cursor: pointer;
  padding-top:1.4vh;
  padding-bottom:1.4vh;
  padding-right:2vw;
  margin-right:2vw;
  border-right: 2px solid #fff;
  height:100%;
}

.userInfoItemStats{
  margin-top: 0vh;
  margin-right:2vw;
  font-size: small;
  padding-right:1vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 50%;
}

p.userInfoStat{
  margin-left:15px;
  text-transform: capitalize;
}


.userInfoLogOut > button{
  margin-top:0.9vh;
  right:2vw;
  border:1px solid #fff;
  color:#fff;
  background-color:transparent;
  cursor:pointer;
  border-radius: 10px;
  padding:5px 10px;
  margin-right:2vw;
}

.userInfoLogOut > button:hover{
  background-color:#0080ff;
  font-weight: 800;
}

.userName{
  margin-top:1.2vh
}

.loadingSignIn{
  position:fixed;
  top:0;
  left:0;
  height:100vh;
  width:100vw;
  background-color:#f3f3f3;
  z-index:250;
  padding-top: 33vh;
  color:#000;
  font-weight: 800;
}

.addVotes{
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
}

.submitted{
  background-color: #10957d;
  font-weight: 800;
  color:#fff;
  letter-spacing: 0.2ch;
  padding-top:25vh;
}

.noAuth{
  background-color:#f76349;
  font-weight: 800;
  color:#fff;
  letter-spacing: 0.2ch;
  padding-top:25vh;
}

.voteForm{
  background-color: #1a8cff;
  font-weight: 800;
  color:#fff;
  letter-spacing: 0.2ch;
  padding-top:25vh;
}

.voteForm input[type=text]{
  border:none;
  background-color: #fff;
  border-radius:5px;
  width:40vw;
  height:40px;
  font-weight: 800;
  font-size: 24px;
  letter-spacing: 0.1ch;
}

.voteForm input[type=file]{
  border:none;
  background-color: #fff;
  color:#1a8cff;
  border-radius:5px;
  width:40vw;
  height:40px;
  font-weight: 800;
  font-size: 24px;
  letter-spacing: 0.1ch;
}

.voteForm input::file-selector-button{
  border:1px solid #1a8cff;
  background-color: #5a96d1;
  color:#fff;
  border-radius:5px;
  height:40px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.1ch;
}

.voteForm button{
  border:1px solid #fff;
  border-radius: 5px;
  color:#fff;
  background-color: transparent;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  padding:10px 20px;
  letter-spacing: 0.1ch;
}

.voteForm button:hover{
  background-color: #0080ff;
  font-weight: bolder;
}



.pdfButton{
  cursor: pointer;
}

.pdfButton:hover{
color: #1a8cff;
}

.initReleaseOver{
  background-color: #818181c2;
  width:100vw;
  height:100vh;
  z-index:24999;
  position:fixed;
  top:0;
  left:0;
  display:block;
}

.initRelease{
  width:50vw;
  height:50vh;
  left:25vw;
  top:25vh;
  z-index:25000;
  background-color: #fff;
  position: fixed;
  border-radius:10px;
  border:2px solid #1a8cff;
}


.newCustomItemButton1{
  position:fixed;
  z-index:1000;
  bottom:2vh;
  left:0.25vw;
}

.newCustomItemButton{
  position:fixed;
  z-index:1000;
  bottom:10vh;
  left:0.5vw;
}

.newCustomItemButton button{
    background-color: #10957d;
    color:white;
    border-radius:5px;
    margin-right:5px;
    border:none;
    padding:15px 10px;
    cursor:pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-size: 1rem;
}

.newCustomItemButton1 button{
    background-color: #3755da;
    color:white;
    border-radius:5px;
    margin-right:5px;
    border:none;
    padding:15px 10px;
    cursor:pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    font-size: 1rem;
}

.newitemContainer input, select{
  width:500px;
  height:3vh;
  font-weight:800;
}


.newItemForm{
  position:fixed;
  height:75vh;
  width:75vw;
  top:12.5vh;
  left:12.5vw;
  z-index:25000;
  background-color:#fff;
  border-radius:25px;
}

.newItemCreated{
  background-color: #17b365;
  color: #fff;
  padding:10px 25px;
  margin:10px 25px;
  font-size:larger;
  font-weight: 800;
}

.title{
  font-size: x-large;
  font-weight: bolder;
  margin-bottom: 5vh;
}
.initalLoad{
  height:100vh;
  width:100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
}

.syncStart{
  background-color: #17b365;
  color: white;
  border-radius: 15px;
  border:none;
  padding:15px 25px;
  font-size: large;
}


.currentTicketUpdating{
  display:flex;
  justify-content: center;
  align-items: center;
  padding:10px 25px;
  border-radius:15px;
  margin:0 30vw;
  margin-bottom:5vh;
  background-color: #10957d;
  color:white
}

.updateCard{
  margin-bottom:2.5vh;
  /*display: flex;
  justify-content: center;*/
}

.updateHeader{
  font-weight: bolder;
  /*margin-right: 5vw;*/
}

.updateError{
  color:rgb(156, 43, 43);
}

.updateComplete{
  background-color: #17b365;
  color: white;
  border-radius: 15px;
  border:none;
  padding:15px 25px;
  font-size: large;
  margin:0 30vw;
  margin-bottom: 5vh;
  font-weight: 600;
  cursor: pointer;
}

.mailSent{
  background-color: #178fb3;
  color: white;
  border-radius: 15px;
  border:none;
  padding:15px 25px;
  font-size: large;
  margin:0 30vw;
  margin-bottom: 5vh;
  font-weight: 600;
}

.trelloIcon{
  color:#3D517B;
}

.trelloOption{
  background-color: #3D517B;
  color:#fff;
}



.addToTrelloContainer{
  display:none;
  z-index: 50000;
  position: fixed;
  background-color: #1b1b1b95;
  height:95vh;
  width:90vw;
  top:5vh;
  left:10vw;
}

.addToTrelloForm{
  border-radius: 25px;
  padding:5vh 10vw;
  margin:2.5vh 2vw 2.5vh 2vw;
  border: red;
  background-color: #fff;
  height:90vh;
  top:5vh;
  left:10vw;
}

.closeTrelloAdd{
  float:right;
  font-size: larger;
  cursor: pointer;
}

#addTrelloDescription{
  width: 50vw;
  height: 3vh;
  font-weight:800
}

#addTrelloContent{
width: 50vw;
height: 40vh;
font-weight:800
}

#createTrelloTicket{
  height:6.5vh;
  width:15vw;
  background-color: #3D517B;
  color:white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: large;
  font-weight: 500;
}

#createTrelloTicket:hover{
  background-color: #10957d;
}


#trelloCreatedNotification{
  z-index: 50000;
  display: none;
  background-color: #17b365;
  color: #fff;
  font-size: large;
  position:fixed;
  top:1.5vh;
  font-weight: bolder;
  right:1.5vw;
  padding:2.5vh 5vw;
  border-radius: 15px;
}

.linkToTrelloContainer{
  display:none;
  z-index: 50000;
  position: fixed;
  background-color: #1b1b1b95;
  height:95vh;
  width:90vw;
  top:5vh;
  left:10vw;
  overflow-y: auto;
}


.linkToTrelloForm{
  border-radius: 25px;
  padding:5vh 10vw;
  margin:2.5vh 2vw 2.5vh 2vw;
  border: red;
  background-color: #fff;
  min-height:90vh;
  top:5vh;
  left:10vw;
}

.linkTrelloCard{
  display: inline-flex;
  justify-content: center;
  align-items:baseline;
  background-color: #1a8cff;
  color:#fff;
  border-radius: 10px;
  padding: 1vh 2vw;
  width:12vw;
  margin-left:1.5vw;
  margin-bottom: 1.5vh;
  height:10vh;
  overflow-y: auto;
  cursor:pointer;
  word-wrap: break-word;
}

.linkTrelloCard:hover{
  background-color: #01a3a3;
}

.linkToTrelloTicket{
margin-left:2.5vw;
}

.trelloCommentsPageContainer{
  position:fixed;
  top:0;
  left:0;
  min-height:100vh;
  min-width: 100vw;
  background-color: #1a8cff75;
}

.ticketData{
  float:left;
  text-align: center;
  width:22.5vw;
  background-color: #fff;
  border-radius: 15px;
  position:sticky;
  top:2.5vh;
  left:2.5vw;
  max-height:90vh;
  overflow-y: auto;
  padding:0vh 1.5vw 5vh 1.5vw;
  margin-right:5vw;
}

.projectTicketData{
  float:left;
  text-align: left;
  width:40vw;
  background-color: #fff;
  border-radius: 15px;
  position:sticky;
  top:2.5vh;
  left:1.5vw;
  max-height:70vh;
  overflow-y: auto;
  padding:0vh 1.5vw 5vh 1.5vw;
  margin-right:5vw;
}

.ticketData h3{
  text-align: left;
  color: #01a3a3;
}

.ticketDataContainer{
text-align: left;
margin-bottom: 2.5vh;
white-space: pre-line
}

.ticketDataProject{
  text-align: left;
  margin-bottom: 2.5vh;
  font-size:small;
  font-weight: bolder;
  }

.ticketDataTitle{
  font-size: 20px;
  font-weight: bolder;
  color:#3D517B
}

.commentData{
  float:right;
  text-align: center;
  width:60vw;
  background-color: #fff;
  border-radius: 15px;
  position:sticky;
  top:2.5vh;
  left:2.5vw;
  max-height:79.5vh;
  overflow-y: auto;
  padding:0vh 1.5vw 5vh 1.5vw;
  margin-right:5vw;
}

.specificationPreview{
  float:right;
  text-align: center;
  width:42vw;
  background-color: #fff;
  border-radius: 15px;
  position:sticky;
  top:2.5vh;
  left:1.5vw;
  max-height:79.5vh;
  overflow-y: auto;
  padding:0vh 1.5vw 5vh 1.5vw;
  margin-right:5vw;
}

.trelloComment{
  padding:2vh 2vw;
  background-color: #1a8cff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  margin-bottom:1.5vh;
}

/*
.trelloComment:after{
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 0 31px 23px 0;
  border-color: transparent #1a8dff transparent transparent;
  bottom: -23px;
  left: 94%;
  margin-left: -15.5px;
}
*/

.trelloCommentUserInfo{
  color: rgb(238, 238, 238);
  text-align: left;
  font-size: smaller;
  margin-bottom: 2vh;
}

.trelloCommentText{
  color:#fff;
  font-weight: 500;
  margin-top:1vh;
  text-align: left;
  white-space: pre-line
}

.commentsTitle{
  font-size: 22px;
  font-weight: 800;
  text-align: center;
  position:sticky;
  top:0vh;
  /*margin:5vh 0;*/
  padding:2vh 0;
  width:100%;
  background-color: #fff;
}

.trelloMoveList{
    color: rgb(53, 53, 53);
    padding:1vh 2vw;
    font-size: 14px;
    font-weight: 400;
    text-align: right;
}

.loadingTrelloComments{
  position:fixed;
  top:0;
  left:0;
  width: 100vw;
  height:100vh;
  background-color: #1a8cff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  color: #fff;
}

.trelloCommentsError{
  position:fixed;
  top:0;
  left:0;
  width: 100vw;
  height:100vh;
  background-color:rgb(231, 74, 74);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  color: #fff;
}

.goBackToPlanner{
  margin-top:2.5vh;
  /*color:#1a8cff;*/
  cursor: pointer;
  font-weight: 800;
}


.ticketReleaseData{
  margin-left:5vw;
  margin-top:10vh;
}

.ticketReleaseData:after{
  content: "";
  display: table;
  clear: both;
}

.ticketContainer{
    float: left;
  width: 20vw;
  margin-left: 2vw;
  margin-right: 2vw;
  margin-top:1.5vh;
  padding:2.25vh;
  border-radius:15px;
  height:8vh;
  background-color: #1a8cff;
  color:#fff;
/* Should be removed. Only for demonstration */
}

.selector select{
  width:10vw;
}

.selector{
  margin-top: 1.5vh;;
}

.newReleaseCandidate, .newReleaseCandidate input,option{
  margin-top:2.5vh;
  font-weight: bolder;
}

.deleteRequestContainer{
  padding:20px 15px;
  z-index: 25000;
  display:none;
  position:absolute;
  top:0vh;
  left:0vw;
  height:95vh;
  width:90vw;
  background-color: #000000cb;
}

.deleteRequestForm{
  background-color: white;
  width:50%;
  margin-top:20vh;
  margin-left:25%;
  padding:15px 25px;
  border-radius: 5px;
  border: 1px solid #1a8cff;
}

.deleteRequestTitle, .deleteRequestCheckbox, .deleteRequestSubmit{
margin-top: 2.5vh;
margin-bottom: 2.5vh;
}

.deleteRequestPermit, .deleteRequestCancel{
  margin-left:1vw;
  margin-right:1vw;
  font-size: medium;
  font-weight: bolder;
}

.deleteRequestPermit{
  border:none;
  background-color: #0080ff;
  color:white;
  padding:5px 15px;
  cursor: pointer;
}

.deleteRequestCancel{
  border:none;
  background-color: red;
  color:white;
  padding:5px 15px;
  cursor: pointer;
}

.deleteNotifier{
  color:red;
  font-weight: bolder;
}


.requestFlag{
    background-color: #daa138;
    color:white;
    border-radius:5px;
    margin-right:5px;
    border:none;
    padding:5px 10px;
    cursor:pointer
}

.trelloUploadContainer{
  background-color: rgba(106, 176, 247);
  padding-top:10px;
  padding-bottom:10px;
  width:100%;
  position: -webkit-sticky;
  position:sticky;
  top:38px;
  z-index:1000;
}

.trelloUploadContainer button{
  background-color: #fff;
  border:none;
  padding: 8px 15px;
  font-weight: bolder;
  color:#3D517B;
  border-radius: 5px;
  cursor: pointer;
}

.trelloUploadContainer button:hover{
  background-color: #3D517B;
  color:#fff;

}

.TrelloWrongBoard{
  font-size: larger;
  background-color: red;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  padding-top:5px;
  padding-bottom:5px;
  margin-bottom:5px;
  margin-top:5px;
  cursor:pointer;
}

.TrelloWrongBoard:hover{

  background-color: #ff000025;
  color:red;

}